<template>
  <div class="cityList" v-if="cityHotData && cityData">
    <div class="search">
      <!-- <div class="city">
        <van-icon name="location" />
        <p>{{ locationCity.city }}</p>
      </div> -->
      <div class="input">
        <van-search
          v-model="value"
          shape="round"
          clearable
          show-action
          placeholder="输入城市名或拼音"
          @input="changeInput"
        />
        <!-- <van-field
          clearable
          v-model="value"
          placeholder="输入城市名或拼音"
          @input="changeInput"
        /> -->
      </div>
      <div class="location">
        <div class="title">
          <p>当前定位</p>
        </div>
        <div class="city">
          <div class="left">
            <img src="../../assets/location.png" alt="" />
            <p>{{ cityName }}</p>
          </div>
          <div class="right">
            <p @click="resetLoation">重新定位</p>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%; height: 14px; background: #eee"></div>
    <van-index-bar>
      <!-- <van-index-anchor index="热门城市" /> -->
      <p class="hotTitle">热门城市</p>
      <div class="hotlist">
        <p
          @click="changeCity(item)"
          v-for="(item, index) in cityHotData"
          :key="index"
        >
          {{ item.city }}
        </p>
      </div>
      <div v-for="(item, index) in cityData" :key="index">
        <van-index-anchor :index="item.cate" v-if="item.item.length !== 0" />
        <van-cell
          @click="changeCity(itemName)"
          :title="itemName.city"
          v-for="(itemName, indexName) in item.item"
          :key="indexName"
        />
      </div>
    </van-index-bar>
    <baidu-map class="bm-view" @ready="handler"> </baidu-map>
  </div>
</template>

<script>
import { getAllCity, getHotCity } from "../../utils/api";
// import getCurrentCityName from "../../../static/js/getUserLocation";
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
export default {
  data() {
    return {
      value: "",
      cityData: null,
      cityHotData: null,
      locationCity: {},
      cityName:'',
      readLocationCity: {}
    };
  },
  async mounted() {
    await this.getAllCity();
    await this.getHotCity();
    this.cityName = this.$route.query.name
  },
  components: {
    BaiduMap
  },
  methods: {
    handler({BMap, map}) {
      let that = this
      var myCity = new BMap.LocalCity()
    
        myCity.get(function (result) {
          console.log(BMap,map,"[][][]---",result)
          that.readLocationCity = result
        })
    },
    //重新定位
    resetLoation(){
      this.getCurrentCity();
    },
    //选择城市
    changeCity(item) {
      sessionStorage.setItem("checkCity", JSON.stringify(item));
      this.$router.go(-1);
    },
    //定位
    getCurrentCity() {
      let that = this;
      return new Promise(function () {
        if (that.readLocationCity.name !== "全国") {
            let name = that.readLocationCity.name.split("市")[0];
            that.cityData.map((item) => {
              item.item.map((item1) => {
                if (item1.city === name) {
                  that.locationCity = item1;
                  that.cityName = that.locationCity.city
                  sessionStorage.setItem("checkCity", JSON.stringify(item1));
                }
              });
            });
          } else {
            that.cityData.map((item) => {
              item.item.map((item1) => {
                if (item1.city === "北京") {
                  that.locationCity = item1;
                  that.cityName = that.locationCity.city
                  sessionStorage.setItem("checkCity", JSON.stringify(item1));
                }
              });
            });
          }
        // getCurrentCityName().then((res) => {
        //      console.log("[[[[[===",that,res)     

          
        // });
      });
    },
    //搜索城市
    async changeInput(val) {
      await this.getAllCity();
      if (await val) {
        let newArr = [];
        this.cityData.map((item) => {
          item.item.map((itemCity) => {
            if (itemCity.city.includes(val)) {
              if (newArr.length) {
                newArr.map((itemArr) => {
                  console.log(itemArr);
                });
              } else {
                let obj = {
                  cate: "",
                  item: [],
                };
                obj.cate = item.cate;
                obj.item.push(itemCity);
                newArr.push(obj);
              }
            }
          });
        });
        this.cityData = newArr;
      }
    },
    //获取所有城市
    async getAllCity() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      await getAllCity().then((res) => {
        this.cityData = res.data;
        let obj = JSON.parse(sessionStorage.getItem("checkCity"));
        if (obj) {
          this.locationCity = obj;
        } else {
          this.getCurrentCity();
        }
        this.$toast.clear();
      });
    },
    //获取热门城市
    async getHotCity() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      await getHotCity().then((res) => {
        this.cityHotData = res.data;
        this.$toast.clear();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$primary-color: #ff804d;
/deep/ .van-search__content {
  height: 32px;
  background: #fcfcfc;
  border-radius: 16px;
  border: 1px solid #eeeeee;
  align-items: center;
}
/deep/ .van-field__left-icon .van-icon,
.van-field__right-icon .van-icon {
  color: #999;
}
/deep/ .van-index-anchor--sticky {
  color: $primary-color;
}
/deep/ .van-index-bar__index--active {
  color: $primary-color;
}
/deep/ .van-cell {
  width: 100%;
  // padding: 5px;
  border-radius: 7px;
  color: #333;
  font-size: 12px;
  line-height: 12px;
}
.cityList {
  padding-bottom: 50px;
  width: 100%;
  height: 100vh;
  position: fixed;
  overflow-y: scroll;
  // background: #f2f2f2;
}
.search {
  width: 100%;
  background: #fff;
  padding: 15px 15px;
  // display: flex;
  // align-items: center;
  .location {
    .title {
      margin-top: 10px;
      p {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 12px;
      }
    }
  }
  .city {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    .left {
      display: flex;
      align-items: center;
      img {
        width: 13px;
        height: 16px;
      }
      p {
        font-size: 16px;
        font-weight: 600;
        color: #333333;
        line-height: 22px;
        margin-left: 5px;
      }
    }
    .right {
      font-size: 12px;
      font-weight: 400;
      color: $primary-color;
      line-height: 12px;
    }
  }
  // .city {
  //   width: 50px;
  //   display: flex;
  //   align-items: center;
  //   font-size: 14px;
  //   line-height: 16px;
  //   color: #fff;
  //   p {
  //     margin-left: 2px;
  //   }
  // }
  .input {
    width: 100%;
    height: 49px;
    flex: 1;
    /deep/ .van-search {
      padding: 0;
    }
    /deep/ .van-search__content {
      background: #fcfcfc;
      border-radius: 16px;
      border: 1px solid #eeeeee;
    }
    /deep/ .van-search__action {
      color: $primary-color;
    }
    // margin-left: 10px;
    // /deep/ .van-field__control {
    //   height: 32px;
    //   background: #fcfcfc;
    //   border-radius: 16px;
    //   border: 1px solid #eeeeee;
    // }
  }
}
.hotTitle {
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  line-height: 12px;
  padding: 20px 15px 0;
}
/deep/ .van-index-bar__sidebar {
  color: #999999;
  font-size: 12px;
  line-height: 12px;
}
.hotlist {
  width: 100%;
  padding: 20px 15px 10px;
  background: #fff;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  flex-flow: wrap;
  p {
    width: 21%;
    margin-right: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 12px;
    padding: 8px 0;
    // border: 1px solid $primary-color;
    border-radius: 4px;
    background: #f5f5f5;
    color: #333;
    margin-bottom: 10px;
  }
}
</style>